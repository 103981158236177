import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

//Componentes
import App from "./app";

const themeOptions = createMuiTheme();

ReactDOM.render(
    <ThemeProvider theme={themeOptions}>
        <App />
    </ThemeProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
