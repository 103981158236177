import React from "react";

//Componentes de Material UI
import { Grid, Box, Container } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

//Componentes de Lottie-player
import { Player } from "@lottiefiles/react-lottie-player";

//Estilos de Material UI
import { makeStyles } from "@material-ui/core/styles";

const errorPageStyles = makeStyles((theme) => ({
    player: {
        position: "relative",
        height: "500px",
        width: "500px",

        [theme.breakpoints.down("sm")]: {
            height: "150px",
            width: "180px",
        },
    },
    box: {
        [theme.breakpoints.down("sm")]: {
            height: "30vh",
        },
    },
}));

const ErrorPage = ({ severity, title, msg }) => {
    //===============================================================================================================================================
    //========================================== Renders ============================================================================================
    //===============================================================================================================================================
    const classes = errorPageStyles();

    return (
        <Container>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-end"
                        className={classes.box}
                    >
                        <Box className={classes.player}>
                            <Player
                                autoplay={true}
                                controls={false}
                                loop={true}
                                src={require("../../static/json/animationError.json")}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity={severity}>
                        <AlertTitle>
                            <b>{title}</b>
                        </AlertTitle>
                        {msg}
                    </Alert>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ErrorPage;
