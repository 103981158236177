import { Component } from "react";
import packageJson from "../../package.json";
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    // code from above snippet goes here
};

export default class CacheBuster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            error: false,
            refreshCacheAndReload: () => {
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // delete browser cache and hard reload
                window.location.reload(true);
            },
        };
    }

    componentDidMount() {
        fetch(`${window.location.protocol}//${window.location.host}/meta.json`)
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(
                    latestVersion,
                    currentVersion
                );

                if (shouldForceRefresh) {
                    this.setState({
                        ...this.state,
                        loading: false,
                        isLatestVersion: false,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        isLatestVersion: true,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    error: true,
                    errorMsg: `Error el obtener el meta.json: ${error.message}`,
                });
            });
    }

    render() {
        const {
            loading,
            isLatestVersion,
            refreshCacheAndReload,
            error,
            errorMsg,
        } = this.state;

        if (error) {
            throw new Error(errorMsg);
        }

        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}
