import React, { createRef } from "react";

//css
import "./static/css/login.css";

//Librerias
import Routes from "./Router";
import { isIE } from "react-device-detect";
import { Player } from "@lottiefiles/react-lottie-player";

//Componentes de Material UI
import { CssBaseline, Typography, Button } from "@material-ui/core";

//Componente de notistack
import { SnackbarProvider } from "notistack";

//Componente de Material Pickers
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

//Componentes de DateFns
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { es } from "date-fns/locale";

//Middlewares
import { AbilityContext } from "./config/Can";
import { Ability } from "@casl/ability";
import CacheBuster from "./middlewares/cacheBuster";
import ErrorBoundary from "./middlewares/ErrorBoundary";

const ability = new Ability();

const App = () => {
    if (isIE) {
        return (
            <div className="container">
                <div className="item">
                    <Player
                        autoplay={true}
                        controls={false}
                        loop={true}
                        src={require("./static/json/animationMaintenance.json")}
                        style={{ height: "400px", width: "800px" }}
                    />
                </div>

                <div>
                    <Typography
                        component="h5"
                        variant="h5"
                        gutterBottom
                        style={{ color: "#459fc2" }}
                    >
                        <b>Navegador no soportado.</b>
                    </Typography>
                </div>

                <div>
                    <Typography component="p" variant="subtitle1">
                        Lo sentimos, está aplicación solo puede ser ejecutada en
                        navegadores de ultima generación, por favor intenta abrirla en un
                        navegador diferente.
                    </Typography>
                </div>
            </div>
        );
    }

    const notistackRef = createRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <ErrorBoundary>
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;

                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }

                    return (
                        <AbilityContext.Provider value={ability}>
                            <CssBaseline />
                            <SnackbarProvider
                                ref={notistackRef}
                                action={(key) => (
                                    <Button onClick={onClickDismiss(key)}>
                                        <b>
                                            <span style={{ color: "white" }}>Cerrar</span>
                                        </b>
                                    </Button>
                                )}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                    <Routes />
                                </MuiPickersUtilsProvider>
                            </SnackbarProvider>
                        </AbilityContext.Provider>
                    );
                }}
            </CacheBuster>
        </ErrorBoundary>
    );
};

export default App;
